import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"clientcabin clientcabin-reasons"},[_c('div',{staticClass:"blueish"},[_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"pt-10 pb-6",attrs:{"cols":"12","lg":"9","xl":"7"}},[_c('p',{staticClass:"text-h4 font-weight-medium"},[_vm._v(" Results & Reasons ")]),_c('p',[_vm._v(" See why "+_vm._s(_vm.company)+" can grow your business and the results you can get with our team ")])])],1)],1)],1),_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"py-6 py-md-10",attrs:{"cols":"12","lg":"9","xl":"7"}},[_c(VContainer,{staticClass:"pa-0"},[_c(VRow,_vm._l((_vm.reasonsByDate),function(reason,index){return _c(VCol,{key:index,attrs:{"cols":"12","lg":"4"}},[_c(VCard,{staticClass:"item-card mx-auto",attrs:{"to":reason.link}},[_c(VImg,{attrs:{"src":require(
                                            `@/assets/img/clientcabin${reason.image}`
                                        ),"height":"220px"}}),_c(VCardTitle,[_c('div',{staticClass:"primary--text mt-2",domProps:{"innerHTML":_vm._s(reason.title)}})]),_c(VCardSubtitle,{staticClass:"mt-0 text-body-2 black--text"},[_vm._v(" "+_vm._s(reason.description)+" "),_c('div',{staticClass:"primary--text mt-2 text-subtitle-1"},[_vm._v(" Read More ")])])],1)],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"blueish"},[_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"pt-10 pb-6",attrs:{"cols":"12","lg":"9","xl":"7"}},[_c('p',{staticClass:"text-h4 font-weight-medium"},[_c('b',[_vm._v(_vm._s(_vm.company))]),_vm._v(" Customer Stories ")])])],1)],1)],1),_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"py-6 py-md-10",attrs:{"cols":"12","lg":"9","xl":"7"}},[_c(VContainer,{staticClass:"pa-0"},[_c(VRow,_vm._l((_vm.storiesByDate),function(story,index){return _c(VCol,{key:index,attrs:{"cols":"12","lg":"4"}},[_c(VCard,{staticClass:"item-card mx-auto",attrs:{"to":story.link}},[_c(VImg,{attrs:{"src":require(
                                            `@/assets/img/clientcabin${story.image}`
                                        ),"max-height":"220px"}}),_c(VCardTitle,[_c('div',{staticClass:"primary--text mt-2",domProps:{"innerHTML":_vm._s(story.title)}})]),_c(VCardSubtitle,{staticClass:"mt-0 text-body-2 black--text"},[_vm._v(" "+_vm._s(story.description)+" "),_c('div',{staticClass:"primary--text mt-2 text-subtitle-1"},[_vm._v(" Read More ")])])],1)],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }