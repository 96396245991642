<template>
    <div class="clientcabin clientcabin-reasons">
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="pt-10 pb-6">
                        <p class="text-h4 font-weight-medium">
                            Results & Reasons
                        </p>
                        <p>
                            See why {{ company }} can grow your business and the
                            results you can get with our team
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <v-container class="pa-0">
                        <v-row>
                            <v-col
                                v-for="(reason, index) in reasonsByDate"
                                :key="index"
                                cols="12"
                                lg="4"
                            >
                                <v-card
                                    class="item-card mx-auto"
                                    :to="reason.link"
                                >
                                    <v-img
                                        :src="
                                            require(
                                                `@/assets/img/clientcabin${reason.image}`
                                            )
                                        "
                                        height="220px"
                                    />

                                    <v-card-title>
                                        <!-- eslint-disable vue/no-v-html -->
                                        <div
                                            class="primary--text mt-2"
                                            v-html="reason.title"
                                        ></div>
                                        <!-- eslint-enable -->
                                    </v-card-title>

                                    <v-card-subtitle
                                        class="mt-0 text-body-2 black--text"
                                    >
                                        {{ reason.description }}
                                        <div
                                            class="primary--text mt-2 text-subtitle-1"
                                        >
                                            Read More
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="pt-10 pb-6">
                        <p class="text-h4 font-weight-medium">
                            <b>{{ company }}</b>
                            Customer Stories
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <v-container class="pa-0">
                        <v-row>
                            <v-col
                                v-for="(story, index) in storiesByDate"
                                :key="index"
                                cols="12"
                                lg="4"
                            >
                                <v-card
                                    class="item-card mx-auto"
                                    :to="story.link"
                                >
                                    <v-img
                                        :src="
                                            require(
                                                `@/assets/img/clientcabin${story.image}`
                                            )
                                        "
                                        max-height="220px"
                                    />

                                    <v-card-title>
                                        <!-- eslint-disable vue/no-v-html -->
                                        <div
                                            class="primary--text mt-2"
                                            v-html="story.title"
                                        ></div>
                                        <!-- eslint-enable -->
                                    </v-card-title>

                                    <v-card-subtitle
                                        class="mt-0 text-body-2 black--text"
                                    >
                                        {{ story.description }}
                                        <div
                                            class="primary--text mt-2 text-subtitle-1"
                                        >
                                            Read More
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

interface ClientCabinBlogPost {
    link: string;
    image: string;
    title: string;
    description: string;
}

@Component
export default class Reasons extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return null;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }

    get reasonsByDate() {
        return this.reasons.reverse();
    }

    get storiesByDate() {
        return this.stories.reverse();
    }

    reasons: ClientCabinBlogPost[] = [
        {
            link: '/reasons/megaphone-effect',
            image: '/reasons/boost-your-brand/1.png',
            title: `The ${this.company} Effect: That Gets Your Brand Heard By A Massive Audience`,
            description: `${this.company} helps brands get more famous and trustworthy by sharing their content on big websites, podcasts, image directories, video platforms, and influential blogs that lots of people follow.`
        },
        {
            link: '/reasons/paid-advertising-vs-content-marketing',
            image: '/reasons/content-marketing/1.png',
            title: 'Paid Advertising vs. Content Marketing Comparison: Are Facebook Ads Worth It?',
            description:
                'Did you know that 61% of consumers are influenced by custom content? If that doesn’t pique your interest in content marketing then we don’t know what will!'
        },
        {
            link: '/reasons/techcrunch-wired-article-coverage-cost-to-submit-is-it-worth-it',
            image: '/reasons/cost-to-submit/1.png',
            title: 'Forbes, TechCrunch & Wired Article Coverage | Cost to Submit & Is it Worth It?',
            description: `Find out why ${this.company} skips submitting to TechCrunch & Wired, opting for strategies that boost business ROI and drive long-term growth.`
        },
        {
            link: '/reasons/zero-traffic-keywords-have-huge-search-volume',
            image: '/reasons/zero-traffic-keywords/1.png',
            title: 'Zero Traffic Keywords Have Huge Search Volume',
            description:
                'Even though keyword research tools might show that certain phrases like zero traffic keywords have no traffic, such keywords can be gold for businesses.'
        },
        {
            link: '/reasons/theres-no-such-thing-as-bad-publicity',
            image: '/reasons/bad-publicity/1.png',
            title: 'There’s No Such Thing As Bad Publicity: Why Brands Work Better When They Tell Their Own Stories',
            description:
                'There’s more than one way to stack an egg. Or is it crack an egg? Anyway, the point is this: if it’s broken eggs you want, it’s up to you as to how you’ll get it done. Building a successful brand is no different – there are a lot of different tactics that can help you get there. Which route will you take?'
        },
        {
            link: '/reasons/keyword-tools-are-keeping-you-poor-stop-using-them',
            image: '/reasons/keyword-tools/1.png',
            title: 'Keyword Tools Are Keeping You Poor, Stop Using Them',
            description:
                'Do things smarter. Scale from there. Learn how to find the low competition, high value keywords nobody else is looking for!'
        },
        {
            link: '/reasons/why-e-commerce-stores-fail',
            image: '/reasons/e-commerce-stores/1.png',
            title: 'Why E-commerce Stores Fail: How To Not Become Yet Another Shopify Flop',
            description:
                'Some of the most common reasons behind e-commerce store failures include an inability to focus on a single niche, poor store design, a "get rich quick" mentality.'
        }
    ];

    stories: ClientCabinBlogPost[] = [
        {
            link: '/reasons/successful-wine-marketing-example',
            image: '/stories/successful-wine-marketing-example/1.png',
            title: 'Successful Wine Marketing Example <br /> Content Plan Strategy Grows Blog 1100% in 21 Months',
            description:
                'While every business is different, the results achieved by the winery store demonstrate the potential of content marketing and the power of our content amplification technique.'
        },
        {
            link: '/reasons/online-medical-store',
            image: '/stories/online-medical-store/1.png',
            title: 'Online Medical Store Generates Millions in Sales From Just 113 Pieces of Content in Less Than 12 Months',
            description:
                'This online medical store had only 561 organic visitors (cumulative 2021) to their Shopify store for a lung cleansing device… which is kind of non-existent traffic.'
        },
        {
            link: '/reasons/car-dealership-marketing-strategy-example',
            image: '/stories/car-dealership/1.png',
            title: 'Car Dealership Marketing Strategy Example: Keyword Ideas & Content Plan Boost Organic Traffic by 76.7%',
            description:
                'Car Dealership Marketing Strategy Example: Keyword Ideas & Content Plan Boost Organic Traffic by 76.7% Share article link An automobile dealership attracted 76.7% more website'
        },
        {
            link: '/reasons/content-amplification-strategy',
            image: '/stories/content-amplification-strategy/1.png',
            title: 'Using Content Amplification Strategies To Drive Leads & Conversions For NYC Commercial Spaces',
            description:
                ' According to marketers, generating traffic is the top problem for businesses today. Businesses struggle with understanding their audiences and explaining their products and services.'
        },
        {
            link: '/reasons/roofing-company-on-page-1-of-google-in-3-months',
            image: '/stories/roofing-company/1.png',
            title: 'Roofing Company Marketing Strategy & Branding Plan Puts Texas Contractor On Page 1 Of Google in 3 Months',
            description:
                'The roofing business is a major industry in the Dallas area, and we knew that we had to give our client an edge over their competition.'
        },
        {
            link: '/reasons/almost-fired',
            image: '/stories/almost-fired/1.png',
            title: 'We almost got fired: 14,700 clicks at 10x ROAS!',
            description: `We experienced this earlier this year, with a customer who didn’t trust the ${this.company} process despite all the numbers indicating that they should, resulting in that we almost got fired.`
        }
    ];
}
</script>

<style lang="scss" scoped>
.clientcabin-reasons::v-deep {
    background-color: $white;
    color: $black;

    .item-card {
        display: flex;
        flex-direction: column;
        height: 100%;

        .v-card__title {
            word-break: keep-all;
        }

        .v-card__subtitle {
            flex-grow: 100;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
</style>
